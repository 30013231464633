.pagination {
  height: 36px;
}
.page-link {
  background-color: #212529ba;
  color: white;
}
.page-item.active .page-link {
  background-color: white;
  color: black;
}
.pagination > li > a,
.pagination > li > span {
  background-color: rgb(54, 105, 217);
}
.MuiTypography-paragraph {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
