/* .align-items-center {
    -ms-flex-align: center!important;
    align-items: center!important;
  }
  .d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
  } */

span {
  font-size: 15px;
}

.box {
  padding: 10px 30px;
}

.box-part {
  background: rgb(253, 253, 253);
  border-radius: 0;
  padding: 60px 10px;
  margin: 30px 0px;
  transition: 0.5s;
  border-radius: 20px;
}
.box-part:hover {
  box-shadow: 40px 50px 50px -60px rgba(0, 0, 0, 0.5);
}
.text {
  margin: 20px 0px;
  color: black;
}

.fa {
  color: #4183d7;
  font-size: 50px;
}
.text2 {
  color: #4183d7;
}
